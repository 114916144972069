import React from 'react';
import queryString from 'query-string';
import Config from '../../Config/Config';
import './AppLogo.scss';

export default function AppLogo() {
  const { whiteLabelId } = queryString.parse(window.location.search);
  let logo = (
    <div className="leoforce-logo">
      <img src={`${process.env.PUBLIC_URL}/leoforce-logo.svg`} alt="leoforce logo" className="leoforce-logo-img" />
    </div>
  );

  if (whiteLabelId) {
    logo = (
      <div className="leoforce-logo">
        <img src={`${Config.urls.getWhiteLabelResourceUrl(whiteLabelId)}/logo.png`} alt="whitelabel logo" />
      </div>
    );
  }
  return logo;
}
